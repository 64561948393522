/* Hide scrollbar for Chrome, Safari and Opera */
.hidesScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidesScrollBar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.applicationCardBody {
  overflow-y: scroll;
  max-height: 25rem;
}
