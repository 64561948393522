a, a:visited {
  text-decoration: none;
}

.bg-dark-home,
.modal-content {
  background-color: rgb(49, 54, 60);
}

.bg-dark-secondary {
  background-color: rgba(108, 117, 125, 0.4);
}

.modal-90w {
  width: 90vw;
  max-width: none !important;
}

.modal-body.confirm p {
  margin: 0;
}

.modal-body.confirm h2 {
  margin: 0;
}

.modal-dialog.add-relation .modal-header h1 {
  margin: 0;
}

.modal-dialog.add-relation .table {
  table-layout: fixed;
}

.modal-dialog.add-relation .table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alert-fit-content {
  display: inline-block;
  width: fit-content;
  padding: 0.5rem 3rem 0.5rem 0.5rem !important;
}

.alert-fit-content button.btn-close {
  height: inherit !important;
}

.btn-xs {
  padding: 0rem 0.5rem;
  font-size: .675rem;
  border-radius: 0.2rem;
}

.home-tile-container {
  width: 11.5rem;
}

.home-tile-body {
  height: 7.5rem;
}
